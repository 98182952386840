import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "@v4/gatsby-theme-talend/src/components/Seo/Seo";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import RestletMap from "../components/restletMap/RestletMap";
import { I18nProvider } from "@v4/utils/i18nContext";

export const query = graphql`
    query RestletMapQuery {
        contentfulMenu(name: { eq: "Main Nav" }, node_locale: { eq: "en" }) {
            ...MainMenu
        }
        footerNav: contentfulMenu(name: { eq: "Footer Nav" }, node_locale: { eq: "en" }) {
            ...FooterNav
        }
        footerSocials: contentfulMenu(name: { eq: "Footer Socials" }, node_locale: { eq: "en" }) {
            ...FooterSocials
        }
        footerSubMenu: contentfulMenu(name: { eq: "Footer Sub Menu" }, node_locale: { eq: "en" }) {
            ...FooterSubMenu
        }
    }
`;

const StatusMap = ({ data }) => {
    const footerData = {
        footerNav: data.footerNav,
        footerSocials: data.footerSocials,
        footerSubMenu: data.footerSubMenu,
    };

    return (
        <I18nProvider translatedPaths={{ en: "/http-status-map/" }} locale={"en"}>
            <>
                <Layout headerMenu={data.contentfulMenu} footerMenu={footerData}>
                    <SEO title="HTTP Status Map" />
                    <Helmet bodyAttributes={{ class: "hasOpaqueHeader" }} />
                    <RestletMap />
                </Layout>
            </>
        </I18nProvider>
    );
};

export default StatusMap;
