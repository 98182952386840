import React, { useEffect } from "react";
import * as d3 from "d3";
import {
    configureTooltipBackground,
    getStationCode,
    getCodeFamilyColor,
    codes,
    getDimensions,
    fillTooltipContent,
    displayTooltip,
    hideTooltip,
    states,
} from "./restletMapHelpers";
import RestletSVG from "../../assets/svg/restlet-map.inline.svg";
import "./restletmap.css";

const RestletMap = () => {
    const getMouseQuarter = (dimension) => {
        const pageX = d3.event.pageX;
        const pageY = d3.event.pageY;

        const centerWidth = dimension.width / 2;
        const centerHeight = dimension.height / 2;

        if (pageX <= centerWidth && pageY <= centerHeight) {
            return 1;
        } else if (pageX > centerWidth && pageY <= centerHeight) {
            return 2;
        } else if (pageX > centerWidth && pageY > centerHeight) {
            return 3;
        } else if (pageX <= centerWidth && pageY > centerHeight) {
            return 4;
        }
    };

    const moveTooltip = (tooltipDiv) => {
        const dimensions = getDimensions();

        const tooltipDimensions = tooltipDiv.node().getBoundingClientRect();

        const quarter = getMouseQuarter(dimensions);
        if (quarter === 1) {
            tooltipDiv.style("left", d3.event.pageX + "px").style("top", d3.event.pageY + "px");
        } else if (quarter === 2) {
            tooltipDiv
                .style("left", d3.event.pageX - tooltipDimensions.width + "px")
                .style("top", d3.event.pageY - tooltipDimensions.height + "px");
        } else if (quarter === 3) {
            tooltipDiv
                .style("left", d3.event.pageX + "px")
                .style("top", d3.event.pageY - tooltipDimensions.height + "px");
        } else if (quarter === 4) {
            tooltipDiv
                .style("left", d3.event.pageX + "px")
                .style("top", d3.event.pageY - tooltipDimensions.height + "px");
        }
    };

    useEffect(() => {
        const tooltipDiv = d3.select("#tooltip");

        d3.selectAll(".station")
            .on("mouseenter", function(d) {
                // Format: code-302
                const id = d3
                    .select(this)
                    .attr("id")
                    .replace("code-", "");

                d3.select(this).style("fill", getCodeFamilyColor(id));

                const code = codes[id];
                states.code = id;

                displayTooltip(tooltipDiv);
                fillTooltipContent(tooltipDiv, code);
                moveTooltip(tooltipDiv);
                configureTooltipBackground(id, tooltipDiv);
            })
            .on("mouseleave", function(d) {
                states.justMouseOut = true;
                d3.select(this).style("fill", "white");
                hideTooltip(tooltipDiv);
            });

        d3.selectAll(".station-label")
            .on("mouseenter", function(d) {
                // Format: _x34_05_Method__x0D_Not_Allowed
                const id = getStationCode(d3.select(this).attr("id"));

                d3.select(this).style("fill", getCodeFamilyColor(id));

                const code = codes[id];
                states.code = id;

                displayTooltip(tooltipDiv);
                fillTooltipContent(tooltipDiv, code);
                moveTooltip(tooltipDiv);
                configureTooltipBackground(id, tooltipDiv);

                d3.select("#code-" + id).style("fill", getCodeFamilyColor(id));
            })
            .on("mouseleave", function(d) {
                states.justMouseOut = true;

                d3.select(this).style("fill", "white");
                hideTooltip(tooltipDiv);
                const id = getStationCode(d3.select(this).attr("id"));
                d3.select("#code-" + id).style("fill", "white");
            });

        tooltipDiv
            .on("mouseenter", function() {
                if (states.justMouseOut) {
                    displayTooltip(tooltipDiv);
                    const id = states.code;
                    d3.select("#code-" + id).style("fill", getCodeFamilyColor(id));
                    states.justMouseOut = false;
                }
            })
            .on("mouseleave", function() {
                hideTooltip(tooltipDiv);
                const id = states.code;
                d3.select("#code-" + id).style("fill", "white");
            });
    });

    return (
        <div className={`restletMapContainer`}>
            <div id="tooltip">&nbsp;</div>
            <RestletSVG />
        </div>
    );
};

export default RestletMap;
